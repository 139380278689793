export default {
  install: (Vue, options) => {
    Vue.prototype.$t = (key) => {
      let val = key.split('.').reduce((o, i) => {
        if (o) return o[i]
      }, options)
      if (!val) {
        console.log('No translation found for ' + key)
        return key
      }
      return val
    }
  }
}