import Vue from 'vue'
import Router from 'vue-router'
import Cookies from 'js-cookie'
import * as Sentry from '@sentry/browser'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      name: '',
      component: () => import('@/containers/DefaultContainer'),
      children: [
        {
          path: '',
          name: 'dashboard',
          component: () => import('@/views/Dashboard'),
        },
        {
          path: 'news',
          name: 'news',
          component: () => import('@/views/News'),
        },
        {
          path: 'login',
          name: 'login',
          component: () => import('@/views/Login'),
        },
        {
          path: 'password-reset/:username/:resetKey',
          name: 'password-reset',
          props: true,
          component: () => import('@/views/PasswordReset'),
        },
        {
          path: 'users',
          name: 'user-search',
          component: () => import('@/views/Users'),
        },
        {
          path: 'users/:id',
          props: true,
          component: () => import('@/containers/UserDetailContainer'),
          children: [
            {
              path: 'view',
              name: 'user-detail',
              component: () => import('@/views/UserDetail'),
            },
            {
              path: 'edit',
              name: 'user-detail-edit',
              component: () => import('@/views/UserDetailEdit'),
            },
          ]
        },        
        {
          path: 'labels',
          name: 'label-search',
          component: () => import('@/views/LabelSearch'),
        },
        {
          path: 'labels/:id',
          props: true,
          component: () => import('@/containers/LabelDetailContainer'),
          children: [
            {
              path: 'view',
              name: 'label-detail',
              component: () => import('@/views/LabelDetail'),
            },
            {
              path: 'edit',
              name: 'label-detail-edit',
              component: () => import('@/views/LabelDetailEdit'),
            },
          ]
        },        
        {
          path: 'contracts',
          name: 'contract-search',
          component: () => import('@/views/Contracts'),
        },
        {
          path: 'contracts/:id',
          props: true,
          component: () => import('@/containers/ContractDetailContainer'),
          children: [
            {
              path: 'view',
              name: 'contract-detail',
              component: () => import('@/views/ContractDetail'),
            },
            {
              path: 'edit',
              name: 'contract-detail-edit',
              component: () => import('@/views/ContractDetailEdit'),
            },
          ]
        },        
        {
          path: 'playlists',
          name: 'release-search',
          component: () => import('@/views/Releases'),
          props: route => ({
            activeFilter: {
              q: route.query.q || '',
              LabelID: route.query.labelId || '',
              DeliveryStatusID: route.query.deliveryStatusID || '',
              ReleaseDate: route.query.releaseDate || '',
            },
            startAt: Number(route.query['start-at'] || 1)
          })
        },
        {
          path: 'playlists/:id',
          props: true,
          component: () => import('@/containers/ReleaseDetailContainer'),
          children: [
            {
              path: 'view',
              name: 'release-detail',
              component: () => import('@/views/ReleaseDetail'),
            },
            {
              path: 'outlets',
              name: 'release-detail-outlets',
              component: () => import('@/views/ReleaseDetailOutlets'),
            },
            {
              path: 'edit',
              name: 'release-detail-edit',
              component: () => import('@/views/ReleaseDetailEdit'),
            },
          ]
        },        
        {
          path: 'tracks',
          name: 'track-search',
          component: () => import('@/views/Tracks'),
          props: route => ({
            activeFilter: {
              q: route.query.q || '',
              LabelID: route.query.labelId || '',
              PreferredSource: 'preferredSource' in route.query ? route.query.preferredSource : '',
            },
            startAt: Number(route.query['start-at'] || 1)
          })
        },
        {
          path: 'tracks/:id',
          props: true,
          component: () => import('@/containers/TrackDetailContainer'),
          children: [
            {
              path: 'view',
              name: 'track-detail',
              component: () => import('@/views/TrackDetail'),
            },
          ]
        },        
        /* Disabled
        {
          path: 'playlists/:albumId/track/:id',
          props: true,
          component: () => import('@/containers/TrackDetailContainer'),
          children: [
            {
              path: 'view',
              name: 'release-track-detail',    
              component: () => import('@/views/TrackDetail'),
            },
            {
              path: 'edit',
              name: 'release-track-detail-edit',    
              component: () => import('@/views/TrackDetailEdit'),
            }
          ]
        },*/
        {
          path: 'playlist-concepts-create',
          name: 'release-concepts-create',
          component: () => import('@/views/ReleaseConceptCreate.vue'),
        },        
        {
          path: 'playlist-concepts/:id',
          name: 'release-concepts',
          props: true,
          component: () => import('@/views/ReleaseConcept.vue'),
        },        
        {
          path: 'trends',
          name: 'trends',
          props: true,
          component: () => import('@/views/Trends.vue'),
        },        
        {
          path: 'royalty-analytics',
          name: 'royalty-analytics',
          props: true,
          component: () => import('@/views/RoyaltyAnalytics.vue'),
        },        
        {
          path: 'royalty-accounting',
          name: 'royalty-accounting',
          props: true,
          component: () => import('@/views/RoyaltyAccounting.vue'),
        },        
        {
          path: 'accounting-rule/:id',
          name: 'accounting-rule',
          props: true,
          component: () => import('@/views/AccountingRule.vue'),
        }, 
        {
          path: 'accounting-rule-group/:id',
          name: 'accounting-rule-group',
          props: true,
          component: () => import('@/views/AccountingRuleGroup.vue'),
        },
        {
          path: 'accounting-statement-spec/:id',
          name: 'accounting-statement-spec',
          props: true,
          component: () => import('@/views/AccountingStatementSpec.vue'),
        },        
        {
          path: 'accounting-debug',
          name: 'accounting-debug',
          props: true,
          component: () => import('@/views/AccountingDebug.vue'),
        },        
        {
          path: 'exports',
          name: 'exports',
          props: true,
          component: () => import('@/views/Exports.vue'),
        },        
        {
          path: 'impersonate/:id',
          name: 'impersonate',
          props: true,
          component: () => import('@/views/Impersonate.vue'),
        },        
        {
          path: '*',
          name: 'notfound',
          component: () => import('@/views/NotFound'),
        },
      ]
    },
  ]
})

router.beforeEach(async (to, from, next) => {
  if (!router.app.$data?.loggedIn && localStorage.getItem('jwt') != null) {
    let jwt = localStorage.getItem('jwt')

    try {
      const response = await router.app.$http.post('login', {
        token: jwt
      })
    
      localStorage.setItem('user', JSON.stringify())
      localStorage.setItem('jwt', response.data.token)
      Cookies.set('jwt', response.data.token)
      router.app.$data.me = response.data.user
      router.app.$data.loggedIn = true
      Sentry.setUser({
        username: router.app.$data.me.Username,
        email: router.app.$data.me.Email,
      })

      router.app.$http.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token;

      // Check impersonating
      router.app.$data.adminToken = localStorage.getItem('adminToken')
      
      return next()

    } catch {
      localStorage.removeItem('jwt')
    }
  }

  if (router.app.$data != undefined)
    router.app.$data.crumbs = []

  if ((!['login', 'password-reset'].includes(to.name)) && ((router.app.$data == undefined) || (router.app.$data.me == null))) {
    console.log('redirect to login')
    next({
      name: 'login',
      params: { nextUrl: to.fullPath }
    })
  } else {
    next();
  }
})

export default router