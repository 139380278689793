export default {
  'Release': 'Playlist',
  'Change artwork': 'Wijzig artwork',
  'An error occurred while uploading, please try again.': 'Er is iets misgegaan met het uploaden. Probeer het nog eens.',
  'Confirm artwork': 'Bevestig artwork',
  'Problems found with the uploaded file. Please try again.': 'Er zijn problemen gevonden met het bestand.',
  'Confirm': 'Bevestigen',
  'Cancel': 'Annuleer',
  'Enter reason': 'Voer reden in...',
  'Artwork change has been requested. It can take a moment before this will be visible.': 'Artwork wijziging is aangevraagd. Het kan even duren voordat het zichtbaar wordt.',
  'Catalog Number': 'Catalogusnummer',
  'Original Release Date': 'Retail Release Datum',
  'Barcode': 'Barcode',
  'Label Website': 'Label Website',
  'Attribution': 'Artiest',
  'Artist Website': 'Artiest Website',
  'Title': 'Titel',
  'Version': 'Versie',
  'Org Releaseformat': 'Org. Releaseformat',
  'C-Line': 'C-Line',
  'Track count': 'Aantal Tracks',
  'P-Line': 'P-Line',
  'Release Status': 'Release Status',
  'Language': 'Taal',
  'Label': 'Label',
  'Release schedule': 'Release schema',
  'Outlet': 'Portaal',
  'Deliveries': 'Leveringen',
  'Processing:': 'Verwerken:',
  'Change Outlet preferences': 'Portaal keuze aanpassen',
  'Take down this release': 'Release intrekken',
  'Albums': 'Playlists',
  'Pending': 'Verwerken',
  'Full take-down': 'Release volledig intrekken',
  'Album outlets': 'Playlist portals',
  'This option is currently unavailable due to maintenance.': 'Deze optie is tijdelijk niet beschikbaar vanwege onderhoudswerkzaamheden',
  'Back': 'Terug',
  'Portals': 'Portalen',
  'Available': 'Beschikbaar',
  'Not Available': 'Niet Beschikbaar',
  'Portals added': 'Portalen toegevoegd',
  'Portals deleted': 'Portalen verwijderd',
  'No changes have been made': 'Er zijn geen wijzigingen gemaakt',
  'Confirm changes': 'Wijzigingen bevestigen',
  'Outlet selection': 'Portaal keuze',
  'Select all': 'Selecteer alle',
  'Deselect all': 'Selecteer geen',
  'Select at least one outlet to proceed': 'Selecteer minstens 1 portaal om verder te gaan',
  'Proceed with next step': 'Ga verder met de volgende stap',
  'Album': 'Playlist',
  'Take Down Pending': 'Take Down wordt verwerkt',
  'Territories are not allowed to be in both lists': 'Waarden mogen niet in beide kolommen voorkomen. Dit heft elkaar op.',
  'At least one area has to be selected': 'Er moet minstens één gebied geselecteerd zijn.',
  'Excluding world is not allowed': 'Wereld is uitgesloten, dit is niet toegestaan.',
}