export default {
  "af": "Afrikaans",
  "af_NA": "Afrikaans (Namibië)",
  "af_ZA": "Afrikaans (Zuid-Afrika)",
  "agq": "Aghem",
  "agq_CM": "Aghem (Kameroen)",
  "ak": "Akan",
  "ak_GH": "Akan (Ghana)",
  "am": "Amhaars",
  "am_ET": "Amhaars (Ethiopië)",
  "ar": "Arabisch",
  "ar_001": "Arabisch (wereld)",
  "ar_AE": "Arabisch (Verenigde Arabische Emiraten)",
  "ar_BH": "Arabisch (Bahrein)",
  "ar_DJ": "Arabisch (Djibouti)",
  "ar_DZ": "Arabisch (Algerije)",
  "ar_EG": "Arabisch (Egypte)",
  "ar_EH": "Arabisch (Westelijke Sahara)",
  "ar_ER": "Arabisch (Eritrea)",
  "ar_IL": "Arabisch (Israël)",
  "ar_IQ": "Arabisch (Irak)",
  "ar_JO": "Arabisch (Jordanië)",
  "ar_KM": "Arabisch (Comoren)",
  "ar_KW": "Arabisch (Koeweit)",
  "ar_LB": "Arabisch (Libanon)",
  "ar_LY": "Arabisch (Libië)",
  "ar_MA": "Arabisch (Marokko)",
  "ar_MR": "Arabisch (Mauritanië)",
  "ar_OM": "Arabisch (Oman)",
  "ar_PS": "Arabisch (Palestijnse gebieden)",
  "ar_QA": "Arabisch (Qatar)",
  "ar_SA": "Arabisch (Saoedi-Arabië)",
  "ar_SD": "Arabisch (Soedan)",
  "ar_SO": "Arabisch (Somalië)",
  "ar_SS": "Arabisch (Zuid-Soedan)",
  "ar_SY": "Arabisch (Syrië)",
  "ar_TD": "Arabisch (Tsjaad)",
  "ar_TN": "Arabisch (Tunesië)",
  "ar_YE": "Arabisch (Jemen)",
  "as": "Assamees",
  "as_IN": "Assamees (India)",
  "asa": "Asu",
  "asa_TZ": "Asu (Tanzania)",
  "ast": "Asturisch",
  "ast_ES": "Asturisch (Spanje)",
  "az": "Azerbeidzjaans",
  "az_Cyrl": "Azerbeidzjaans (Cyrillisch)",
  "az_Cyrl_AZ": "Azerbeidzjaans (Cyrillisch, Azerbeidzjan)",
  "az_Latn": "Azerbeidzjaans (Latijns)",
  "az_Latn_AZ": "Azerbeidzjaans (Latijns, Azerbeidzjan)",
  "bas": "Basa",
  "bas_CM": "Basa (Kameroen)",
  "be": "Wit-Russisch",
  "be_BY": "Wit-Russisch (Belarus)",
  "bem": "Bemba",
  "bem_ZM": "Bemba (Zambia)",
  "bez": "Bena",
  "bez_TZ": "Bena (Tanzania)",
  "bg": "Bulgaars",
  "bg_BG": "Bulgaars (Bulgarije)",
  "bm": "Bambara",
  "bm_ML": "Bambara (Mali)",
  "bn": "Bengaals",
  "bn_BD": "Bengaals (Bangladesh)",
  "bn_IN": "Bengaals (India)",
  "bo": "Tibetaans",
  "bo_CN": "Tibetaans (China)",
  "bo_IN": "Tibetaans (India)",
  "br": "Bretons",
  "br_FR": "Bretons (Frankrijk)",
  "brx": "Bodo",
  "brx_IN": "Bodo (India)",
  "bs": "Bosnisch",
  "bs_Cyrl": "Bosnisch (Cyrillisch)",
  "bs_Cyrl_BA": "Bosnisch (Cyrillisch, Bosnië en Herzegovina)",
  "bs_Latn": "Bosnisch (Latijns)",
  "bs_Latn_BA": "Bosnisch (Latijns, Bosnië en Herzegovina)",
  "ca": "Catalaans",
  "ca_AD": "Catalaans (Andorra)",
  "ca_ES": "Catalaans (Spanje)",
  "ca_FR": "Catalaans (Frankrijk)",
  "ca_IT": "Catalaans (Italië)",
  "ccp": "ccp",
  "ccp_BD": "ccp (Bangladesh)",
  "ccp_IN": "ccp (India)",
  "ce": "Tsjetsjeens",
  "ce_RU": "Tsjetsjeens (Rusland)",
  "cgg": "Chiga",
  "cgg_UG": "Chiga (Oeganda)",
  "chr": "Cherokee",
  "chr_US": "Cherokee (Verenigde Staten)",
  "ckb": "Soranî",
  "ckb_IQ": "Soranî (Irak)",
  "ckb_IR": "Soranî (Iran)",
  "cs": "Tsjechisch",
  "cs_CZ": "Tsjechisch (Tsjechië)",
  "cy": "Welsh",
  "cy_GB": "Welsh (Verenigd Koninkrijk)",
  "da": "Deens",
  "da_DK": "Deens (Denemarken)",
  "da_GL": "Deens (Groenland)",
  "dav": "Taita",
  "dav_KE": "Taita (Kenia)",
  "de": "Duits",
  "de_AT": "Duits (Oostenrijk)",
  "de_BE": "Duits (België)",
  "de_CH": "Duits (Zwitserland)",
  "de_DE": "Duits (Duitsland)",
  "de_IT": "Duits (Italië)",
  "de_LI": "Duits (Liechtenstein)",
  "de_LU": "Duits (Luxemburg)",
  "dje": "Zarma",
  "dje_NE": "Zarma (Niger)",
  "dsb": "Nedersorbisch",
  "dsb_DE": "Nedersorbisch (Duitsland)",
  "dua": "Duala",
  "dua_CM": "Duala (Kameroen)",
  "dyo": "Jola-Fonyi",
  "dyo_SN": "Jola-Fonyi (Senegal)",
  "dz": "Dzongkha",
  "dz_BT": "Dzongkha (Bhutan)",
  "ebu": "Embu",
  "ebu_KE": "Embu (Kenia)",
  "ee": "Ewe",
  "ee_GH": "Ewe (Ghana)",
  "ee_TG": "Ewe (Togo)",
  "el": "Grieks",
  "el_CY": "Grieks (Cyprus)",
  "el_GR": "Grieks (Griekenland)",
  "en": "Engels",
  "en_001": "Engels (wereld)",
  "en_150": "Engels (Europa)",
  "en_AG": "Engels (Antigua en Barbuda)",
  "en_AI": "Engels (Anguilla)",
  "en_AS": "Engels (Amerikaans-Samoa)",
  "en_AT": "Engels (Oostenrijk)",
  "en_AU": "Engels (Australië)",
  "en_BB": "Engels (Barbados)",
  "en_BE": "Engels (België)",
  "en_BI": "Engels (Burundi)",
  "en_BM": "Engels (Bermuda)",
  "en_BS": "Engels (Bahama’s)",
  "en_BW": "Engels (Botswana)",
  "en_BZ": "Engels (Belize)",
  "en_CA": "Engels (Canada)",
  "en_CC": "Engels (Cocoseilanden)",
  "en_CH": "Engels (Zwitserland)",
  "en_CK": "Engels (Cookeilanden)",
  "en_CM": "Engels (Kameroen)",
  "en_CX": "Engels (Christmaseiland)",
  "en_CY": "Engels (Cyprus)",
  "en_DE": "Engels (Duitsland)",
  "en_DG": "Engels (Diego Garcia)",
  "en_DK": "Engels (Denemarken)",
  "en_DM": "Engels (Dominica)",
  "en_ER": "Engels (Eritrea)",
  "en_FI": "Engels (Finland)",
  "en_FJ": "Engels (Fiji)",
  "en_FK": "Engels (Falklandeilanden)",
  "en_FM": "Engels (Micronesia)",
  "en_GB": "Engels (Verenigd Koninkrijk)",
  "en_GD": "Engels (Grenada)",
  "en_GG": "Engels (Guernsey)",
  "en_GH": "Engels (Ghana)",
  "en_GI": "Engels (Gibraltar)",
  "en_GM": "Engels (Gambia)",
  "en_GU": "Engels (Guam)",
  "en_GY": "Engels (Guyana)",
  "en_HK": "Engels (Hongkong SAR van China)",
  "en_IE": "Engels (Ierland)",
  "en_IL": "Engels (Israël)",
  "en_IM": "Engels (Isle of Man)",
  "en_IN": "Engels (India)",
  "en_IO": "Engels (Brits Indische Oceaanterritorium)",
  "en_JE": "Engels (Jersey)",
  "en_JM": "Engels (Jamaica)",
  "en_KE": "Engels (Kenia)",
  "en_KI": "Engels (Kiribati)",
  "en_KN": "Engels (Saint Kitts en Nevis)",
  "en_KY": "Engels (Kaaimaneilanden)",
  "en_LC": "Engels (Saint Lucia)",
  "en_LR": "Engels (Liberia)",
  "en_LS": "Engels (Lesotho)",
  "en_MG": "Engels (Madagaskar)",
  "en_MH": "Engels (Marshalleilanden)",
  "en_MO": "Engels (Macau SAR van China)",
  "en_MP": "Engels (Noordelijke Marianen)",
  "en_MS": "Engels (Montserrat)",
  "en_MT": "Engels (Malta)",
  "en_MU": "Engels (Mauritius)",
  "en_MW": "Engels (Malawi)",
  "en_MY": "Engels (Maleisië)",
  "en_NA": "Engels (Namibië)",
  "en_NF": "Engels (Norfolk)",
  "en_NG": "Engels (Nigeria)",
  "en_NL": "Engels (Nederland)",
  "en_NR": "Engels (Nauru)",
  "en_NU": "Engels (Niue)",
  "en_NZ": "Engels (Nieuw-Zeeland)",
  "en_PG": "Engels (Papoea-Nieuw-Guinea)",
  "en_PH": "Engels (Filipijnen)",
  "en_PK": "Engels (Pakistan)",
  "en_PN": "Engels (Pitcairneilanden)",
  "en_PR": "Engels (Puerto Rico)",
  "en_PW": "Engels (Palau)",
  "en_RW": "Engels (Rwanda)",
  "en_SB": "Engels (Salomonseilanden)",
  "en_SC": "Engels (Seychellen)",
  "en_SD": "Engels (Soedan)",
  "en_SE": "Engels (Zweden)",
  "en_SG": "Engels (Singapore)",
  "en_SH": "Engels (Sint-Helena)",
  "en_SI": "Engels (Slovenië)",
  "en_SL": "Engels (Sierra Leone)",
  "en_SS": "Engels (Zuid-Soedan)",
  "en_SX": "Engels (Sint-Maarten)",
  "en_SZ": "Engels (Swaziland)",
  "en_TC": "Engels (Turks- en Caicoseilanden)",
  "en_TK": "Engels (Tokelau)",
  "en_TO": "Engels (Tonga)",
  "en_TT": "Engels (Trinidad en Tobago)",
  "en_TV": "Engels (Tuvalu)",
  "en_TZ": "Engels (Tanzania)",
  "en_UG": "Engels (Oeganda)",
  "en_UM": "Engels (Kleine afgelegen eilanden van de Verenigde Staten)",
  "en_US": "Engels (Verenigde Staten)",
  "en_US_POSIX": "Engels (Verenigde Staten, Computer)",
  "en_VC": "Engels (Saint Vincent en de Grenadines)",
  "en_VG": "Engels (Britse Maagdeneilanden)",
  "en_VI": "Engels (Amerikaanse Maagdeneilanden)",
  "en_VU": "Engels (Vanuatu)",
  "en_WS": "Engels (Samoa)",
  "en_ZA": "Engels (Zuid-Afrika)",
  "en_ZM": "Engels (Zambia)",
  "en_ZW": "Engels (Zimbabwe)",
  "eo": "Esperanto",
  "es": "Spaans",
  "es_419": "Spaans (Latijns-Amerika)",
  "es_AR": "Spaans (Argentinië)",
  "es_BO": "Spaans (Bolivia)",
  "es_BR": "Spaans (Brazilië)",
  "es_BZ": "Spaans (Belize)",
  "es_CL": "Spaans (Chili)",
  "es_CO": "Spaans (Colombia)",
  "es_CR": "Spaans (Costa Rica)",
  "es_CU": "Spaans (Cuba)",
  "es_DO": "Spaans (Dominicaanse Republiek)",
  "es_EA": "Spaans (Ceuta en Melilla)",
  "es_EC": "Spaans (Ecuador)",
  "es_ES": "Spaans (Spanje)",
  "es_GQ": "Spaans (Equatoriaal-Guinea)",
  "es_GT": "Spaans (Guatemala)",
  "es_HN": "Spaans (Honduras)",
  "es_IC": "Spaans (Canarische Eilanden)",
  "es_MX": "Spaans (Mexico)",
  "es_NI": "Spaans (Nicaragua)",
  "es_PA": "Spaans (Panama)",
  "es_PE": "Spaans (Peru)",
  "es_PH": "Spaans (Filipijnen)",
  "es_PR": "Spaans (Puerto Rico)",
  "es_PY": "Spaans (Paraguay)",
  "es_SV": "Spaans (El Salvador)",
  "es_US": "Spaans (Verenigde Staten)",
  "es_UY": "Spaans (Uruguay)",
  "es_VE": "Spaans (Venezuela)",
  "et": "Estisch",
  "et_EE": "Estisch (Estland)",
  "eu": "Baskisch",
  "eu_ES": "Baskisch (Spanje)",
  "ewo": "Ewondo",
  "ewo_CM": "Ewondo (Kameroen)",
  "fa": "Perzisch",
  "fa_AF": "Perzisch (Afghanistan)",
  "fa_IR": "Perzisch (Iran)",
  "ff": "Fulah",
  "ff_CM": "Fulah (Kameroen)",
  "ff_GN": "Fulah (Guinee)",
  "ff_MR": "Fulah (Mauritanië)",
  "ff_SN": "Fulah (Senegal)",
  "fi": "Fins",
  "fi_FI": "Fins (Finland)",
  "fil": "Filipijns",
  "fil_PH": "Filipijns (Filipijnen)",
  "fo": "Faeröers",
  "fo_DK": "Faeröers (Denemarken)",
  "fo_FO": "Faeröers (Faeröer)",
  "fr": "Frans",
  "fr_BE": "Frans (België)",
  "fr_BF": "Frans (Burkina Faso)",
  "fr_BI": "Frans (Burundi)",
  "fr_BJ": "Frans (Benin)",
  "fr_BL": "Frans (Saint-Barthélemy)",
  "fr_CA": "Frans (Canada)",
  "fr_CD": "Frans (Congo-Kinshasa)",
  "fr_CF": "Frans (Centraal-Afrikaanse Republiek)",
  "fr_CG": "Frans (Congo-Brazzaville)",
  "fr_CH": "Frans (Zwitserland)",
  "fr_CI": "Frans (Ivoorkust)",
  "fr_CM": "Frans (Kameroen)",
  "fr_DJ": "Frans (Djibouti)",
  "fr_DZ": "Frans (Algerije)",
  "fr_FR": "Frans (Frankrijk)",
  "fr_GA": "Frans (Gabon)",
  "fr_GF": "Frans (Frans-Guyana)",
  "fr_GN": "Frans (Guinee)",
  "fr_GP": "Frans (Guadeloupe)",
  "fr_GQ": "Frans (Equatoriaal-Guinea)",
  "fr_HT": "Frans (Haïti)",
  "fr_KM": "Frans (Comoren)",
  "fr_LU": "Frans (Luxemburg)",
  "fr_MA": "Frans (Marokko)",
  "fr_MC": "Frans (Monaco)",
  "fr_MF": "Frans (Saint-Martin)",
  "fr_MG": "Frans (Madagaskar)",
  "fr_ML": "Frans (Mali)",
  "fr_MQ": "Frans (Martinique)",
  "fr_MR": "Frans (Mauritanië)",
  "fr_MU": "Frans (Mauritius)",
  "fr_NC": "Frans (Nieuw-Caledonië)",
  "fr_NE": "Frans (Niger)",
  "fr_PF": "Frans (Frans-Polynesië)",
  "fr_PM": "Frans (Saint-Pierre en Miquelon)",
  "fr_RE": "Frans (Réunion)",
  "fr_RW": "Frans (Rwanda)",
  "fr_SC": "Frans (Seychellen)",
  "fr_SN": "Frans (Senegal)",
  "fr_SY": "Frans (Syrië)",
  "fr_TD": "Frans (Tsjaad)",
  "fr_TG": "Frans (Togo)",
  "fr_TN": "Frans (Tunesië)",
  "fr_VU": "Frans (Vanuatu)",
  "fr_WF": "Frans (Wallis en Futuna)",
  "fr_YT": "Frans (Mayotte)",
  "fur": "Friulisch",
  "fur_IT": "Friulisch (Italië)",
  "fy": "Fries",
  "fy_NL": "Fries (Nederland)",
  "ga": "Iers",
  "ga_IE": "Iers (Ierland)",
  "gd": "Schots-Gaelisch",
  "gd_GB": "Schots-Gaelisch (Verenigd Koninkrijk)",
  "gl": "Galicisch",
  "gl_ES": "Galicisch (Spanje)",
  "gsw": "Zwitserduits",
  "gsw_CH": "Zwitserduits (Zwitserland)",
  "gsw_FR": "Zwitserduits (Frankrijk)",
  "gsw_LI": "Zwitserduits (Liechtenstein)",
  "gu": "Gujarati",
  "gu_IN": "Gujarati (India)",
  "guz": "Gusii",
  "guz_KE": "Gusii (Kenia)",
  "gv": "Manx",
  "gv_IM": "Manx (Isle of Man)",
  "ha": "Hausa",
  "ha_GH": "Hausa (Ghana)",
  "ha_NE": "Hausa (Niger)",
  "ha_NG": "Hausa (Nigeria)",
  "haw": "Hawaïaans",
  "haw_US": "Hawaïaans (Verenigde Staten)",
  "he": "Hebreeuws",
  "he_IL": "Hebreeuws (Israël)",
  "hi": "Hindi",
  "hi_IN": "Hindi (India)",
  "hr": "Kroatisch",
  "hr_BA": "Kroatisch (Bosnië en Herzegovina)",
  "hr_HR": "Kroatisch (Kroatië)",
  "hsb": "Oppersorbisch",
  "hsb_DE": "Oppersorbisch (Duitsland)",
  "hu": "Hongaars",
  "hu_HU": "Hongaars (Hongarije)",
  "hy": "Armeens",
  "hy_AM": "Armeens (Armenië)",
  "id": "Indonesisch",
  "id_ID": "Indonesisch (Indonesië)",
  "ig": "Igbo",
  "ig_NG": "Igbo (Nigeria)",
  "ii": "Yi",
  "ii_CN": "Yi (China)",
  "is": "IJslands",
  "is_IS": "IJslands (IJsland)",
  "it": "Italiaans",
  "it_CH": "Italiaans (Zwitserland)",
  "it_IT": "Italiaans (Italië)",
  "it_SM": "Italiaans (San Marino)",
  "it_VA": "Italiaans (Vaticaanstad)",
  "ja": "Japans",
  "ja_JP": "Japans (Japan)",
  "jgo": "Ngomba",
  "jgo_CM": "Ngomba (Kameroen)",
  "jmc": "Machame",
  "jmc_TZ": "Machame (Tanzania)",
  "ka": "Georgisch",
  "ka_GE": "Georgisch (Georgië)",
  "kab": "Kabylisch",
  "kab_DZ": "Kabylisch (Algerije)",
  "kam": "Kamba",
  "kam_KE": "Kamba (Kenia)",
  "kde": "Makonde",
  "kde_TZ": "Makonde (Tanzania)",
  "kea": "Kaapverdisch Creools",
  "kea_CV": "Kaapverdisch Creools (Kaapverdië)",
  "khq": "Koyra Chiini",
  "khq_ML": "Koyra Chiini (Mali)",
  "ki": "Gikuyu",
  "ki_KE": "Gikuyu (Kenia)",
  "kk": "Kazachs",
  "kk_KZ": "Kazachs (Kazachstan)",
  "kkj": "Kako",
  "kkj_CM": "Kako (Kameroen)",
  "kl": "Groenlands",
  "kl_GL": "Groenlands (Groenland)",
  "kln": "Kalenjin",
  "kln_KE": "Kalenjin (Kenia)",
  "km": "Khmer",
  "km_KH": "Khmer (Cambodja)",
  "kn": "Kannada",
  "kn_IN": "Kannada (India)",
  "ko": "Koreaans",
  "ko_KP": "Koreaans (Noord-Korea)",
  "ko_KR": "Koreaans (Zuid-Korea)",
  "kok": "Konkani",
  "kok_IN": "Konkani (India)",
  "ks": "Kasjmiri",
  "ks_IN": "Kasjmiri (India)",
  "ksb": "Shambala",
  "ksb_TZ": "Shambala (Tanzania)",
  "ksf": "Bafia",
  "ksf_CM": "Bafia (Kameroen)",
  "ksh": "Kölsch",
  "ksh_DE": "Kölsch (Duitsland)",
  "kw": "Cornish",
  "kw_GB": "Cornish (Verenigd Koninkrijk)",
  "ky": "Kirgizisch",
  "ky_KG": "Kirgizisch (Kirgizië)",
  "lag": "Langi",
  "lag_TZ": "Langi (Tanzania)",
  "lb": "Luxemburgs",
  "lb_LU": "Luxemburgs (Luxemburg)",
  "lg": "Luganda",
  "lg_UG": "Luganda (Oeganda)",
  "lkt": "Lakota",
  "lkt_US": "Lakota (Verenigde Staten)",
  "ln": "Lingala",
  "ln_AO": "Lingala (Angola)",
  "ln_CD": "Lingala (Congo-Kinshasa)",
  "ln_CF": "Lingala (Centraal-Afrikaanse Republiek)",
  "ln_CG": "Lingala (Congo-Brazzaville)",
  "lo": "Laotiaans",
  "lo_LA": "Laotiaans (Laos)",
  "lrc": "Noordelijk Luri",
  "lrc_IQ": "Noordelijk Luri (Irak)",
  "lrc_IR": "Noordelijk Luri (Iran)",
  "lt": "Litouws",
  "lt_LT": "Litouws (Litouwen)",
  "lu": "Luba-Katanga",
  "lu_CD": "Luba-Katanga (Congo-Kinshasa)",
  "luo": "Luo",
  "luo_KE": "Luo (Kenia)",
  "luy": "Luyia",
  "luy_KE": "Luyia (Kenia)",
  "lv": "Lets",
  "lv_LV": "Lets (Letland)",
  "mas": "Maa",
  "mas_KE": "Maa (Kenia)",
  "mas_TZ": "Maa (Tanzania)",
  "mer": "Meru",
  "mer_KE": "Meru (Kenia)",
  "mfe": "Morisyen",
  "mfe_MU": "Morisyen (Mauritius)",
  "mg": "Malagassisch",
  "mg_MG": "Malagassisch (Madagaskar)",
  "mgh": "Makhuwa-Meetto",
  "mgh_MZ": "Makhuwa-Meetto (Mozambique)",
  "mgo": "Meta’",
  "mgo_CM": "Meta’ (Kameroen)",
  "mk": "Macedonisch",
  "mk_MK": "Macedonisch (Macedonië)",
  "ml": "Malayalam",
  "ml_IN": "Malayalam (India)",
  "mn": "Mongools",
  "mn_MN": "Mongools (Mongolië)",
  "mr": "Marathi",
  "mr_IN": "Marathi (India)",
  "ms": "Maleis",
  "ms_BN": "Maleis (Brunei)",
  "ms_MY": "Maleis (Maleisië)",
  "ms_SG": "Maleis (Singapore)",
  "mt": "Maltees",
  "mt_MT": "Maltees (Malta)",
  "mua": "Mundang",
  "mua_CM": "Mundang (Kameroen)",
  "my": "Birmaans",
  "my_MM": "Birmaans (Myanmar [Birma])",
  "mzn": "Mazanderani",
  "mzn_IR": "Mazanderani (Iran)",
  "naq": "Nama",
  "naq_NA": "Nama (Namibië)",
  "nb": "Noors - Bokmål",
  "nb_NO": "Noors - Bokmål (Noorwegen)",
  "nb_SJ": "Noors - Bokmål (Spitsbergen en Jan Mayen)",
  "nd": "Noord-Ndebele",
  "nd_ZW": "Noord-Ndebele (Zimbabwe)",
  "nds": "Nedersaksisch",
  "nds_DE": "Nedersaksisch (Duitsland)",
  "nds_NL": "Nedersaksisch (Nederland)",
  "ne": "Nepalees",
  "ne_IN": "Nepalees (India)",
  "ne_NP": "Nepalees (Nepal)",
  "nl": "Nederlands",
  "nl_AW": "Nederlands (Aruba)",
  "nl_BE": "Nederlands (België)",
  "nl_BQ": "Nederlands (Caribisch Nederland)",
  "nl_CW": "Nederlands (Curaçao)",
  "nl_NL": "Nederlands (Nederland)",
  "nl_SR": "Nederlands (Suriname)",
  "nl_SX": "Nederlands (Sint-Maarten)",
  "nmg": "Ngumba",
  "nmg_CM": "Ngumba (Kameroen)",
  "nn": "Noors - Nynorsk",
  "nn_NO": "Noors - Nynorsk (Noorwegen)",
  "nnh": "Ngiemboon",
  "nnh_CM": "Ngiemboon (Kameroen)",
  "nus": "Nuer",
  "nus_SS": "Nuer (Zuid-Soedan)",
  "nyn": "Nyankole",
  "nyn_UG": "Nyankole (Oeganda)",
  "om": "Afaan Oromo",
  "om_ET": "Afaan Oromo (Ethiopië)",
  "om_KE": "Afaan Oromo (Kenia)",
  "or": "Odia",
  "or_IN": "Odia (India)",
  "os": "Ossetisch",
  "os_GE": "Ossetisch (Georgië)",
  "os_RU": "Ossetisch (Rusland)",
  "pa": "Punjabi",
  "pa_Arab": "Punjabi (Arabisch)",
  "pa_Arab_PK": "Punjabi (Arabisch, Pakistan)",
  "pa_Guru": "Punjabi (Gurmukhi)",
  "pa_Guru_IN": "Punjabi (Gurmukhi, India)",
  "pl": "Pools",
  "pl_PL": "Pools (Polen)",
  "ps": "Pasjtoe",
  "ps_AF": "Pasjtoe (Afghanistan)",
  "pt": "Portugees",
  "pt_AO": "Portugees (Angola)",
  "pt_BR": "Portugees (Brazilië)",
  "pt_CH": "Portugees (Zwitserland)",
  "pt_CV": "Portugees (Kaapverdië)",
  "pt_GQ": "Portugees (Equatoriaal-Guinea)",
  "pt_GW": "Portugees (Guinee-Bissau)",
  "pt_LU": "Portugees (Luxemburg)",
  "pt_MO": "Portugees (Macau SAR van China)",
  "pt_MZ": "Portugees (Mozambique)",
  "pt_PT": "Portugees (Portugal)",
  "pt_ST": "Portugees (Sao Tomé en Principe)",
  "pt_TL": "Portugees (Oost-Timor)",
  "qu": "Quechua",
  "qu_BO": "Quechua (Bolivia)",
  "qu_EC": "Quechua (Ecuador)",
  "qu_PE": "Quechua (Peru)",
  "rm": "Reto-Romaans",
  "rm_CH": "Reto-Romaans (Zwitserland)",
  "rn": "Kirundi",
  "rn_BI": "Kirundi (Burundi)",
  "ro": "Roemeens",
  "ro_MD": "Roemeens (Moldavië)",
  "ro_RO": "Roemeens (Roemenië)",
  "rof": "Rombo",
  "rof_TZ": "Rombo (Tanzania)",
  "ru": "Russisch",
  "ru_BY": "Russisch (Belarus)",
  "ru_KG": "Russisch (Kirgizië)",
  "ru_KZ": "Russisch (Kazachstan)",
  "ru_MD": "Russisch (Moldavië)",
  "ru_RU": "Russisch (Rusland)",
  "ru_UA": "Russisch (Oekraïne)",
  "rw": "Kinyarwanda",
  "rw_RW": "Kinyarwanda (Rwanda)",
  "rwk": "Rwa",
  "rwk_TZ": "Rwa (Tanzania)",
  "sah": "Jakoets",
  "sah_RU": "Jakoets (Rusland)",
  "saq": "Samburu",
  "saq_KE": "Samburu (Kenia)",
  "sbp": "Sangu",
  "sbp_TZ": "Sangu (Tanzania)",
  "se": "Noord-Samisch",
  "se_FI": "Noord-Samisch (Finland)",
  "se_NO": "Noord-Samisch (Noorwegen)",
  "se_SE": "Noord-Samisch (Zweden)",
  "seh": "Sena",
  "seh_MZ": "Sena (Mozambique)",
  "ses": "Koyraboro Senni",
  "ses_ML": "Koyraboro Senni (Mali)",
  "sg": "Sango",
  "sg_CF": "Sango (Centraal-Afrikaanse Republiek)",
  "shi": "Tashelhiyt",
  "shi_Latn": "Tashelhiyt (Latijns)",
  "shi_Latn_MA": "Tashelhiyt (Latijns, Marokko)",
  "shi_Tfng": "Tashelhiyt (Tifinagh)",
  "shi_Tfng_MA": "Tashelhiyt (Tifinagh, Marokko)",
  "si": "Singalees",
  "si_LK": "Singalees (Sri Lanka)",
  "sk": "Slowaaks",
  "sk_SK": "Slowaaks (Slowakije)",
  "sl": "Sloveens",
  "sl_SI": "Sloveens (Slovenië)",
  "smn": "Inari-Samisch",
  "smn_FI": "Inari-Samisch (Finland)",
  "sn": "Shona",
  "sn_ZW": "Shona (Zimbabwe)",
  "so": "Somalisch",
  "so_DJ": "Somalisch (Djibouti)",
  "so_ET": "Somalisch (Ethiopië)",
  "so_KE": "Somalisch (Kenia)",
  "so_SO": "Somalisch (Somalië)",
  "sq": "Albanees",
  "sq_AL": "Albanees (Albanië)",
  "sq_MK": "Albanees (Macedonië)",
  "sq_XK": "Albanees (Kosovo)",
  "sr": "Servisch",
  "sr_Cyrl": "Servisch (Cyrillisch)",
  "sr_Cyrl_BA": "Servisch (Cyrillisch, Bosnië en Herzegovina)",
  "sr_Cyrl_ME": "Servisch (Cyrillisch, Montenegro)",
  "sr_Cyrl_RS": "Servisch (Cyrillisch, Servië)",
  "sr_Cyrl_XK": "Servisch (Cyrillisch, Kosovo)",
  "sr_Latn": "Servisch (Latijns)",
  "sr_Latn_BA": "Servisch (Latijns, Bosnië en Herzegovina)",
  "sr_Latn_ME": "Servisch (Latijns, Montenegro)",
  "sr_Latn_RS": "Servisch (Latijns, Servië)",
  "sr_Latn_XK": "Servisch (Latijns, Kosovo)",
  "sv": "Zweeds",
  "sv_AX": "Zweeds (Åland)",
  "sv_FI": "Zweeds (Finland)",
  "sv_SE": "Zweeds (Zweden)",
  "sw": "Swahili",
  "sw_CD": "Swahili (Congo-Kinshasa)",
  "sw_KE": "Swahili (Kenia)",
  "sw_TZ": "Swahili (Tanzania)",
  "sw_UG": "Swahili (Oeganda)",
  "ta": "Tamil",
  "ta_IN": "Tamil (India)",
  "ta_LK": "Tamil (Sri Lanka)",
  "ta_MY": "Tamil (Maleisië)",
  "ta_SG": "Tamil (Singapore)",
  "te": "Telugu",
  "te_IN": "Telugu (India)",
  "teo": "Teso",
  "teo_KE": "Teso (Kenia)",
  "teo_UG": "Teso (Oeganda)",
  "tg": "Tadzjieks",
  "tg_TJ": "Tadzjieks (Tadzjikistan)",
  "th": "Thai",
  "th_TH": "Thai (Thailand)",
  "ti": "Tigrinya",
  "ti_ER": "Tigrinya (Eritrea)",
  "ti_ET": "Tigrinya (Ethiopië)",
  "to": "Tongaans",
  "to_TO": "Tongaans (Tonga)",
  "tr": "Turks",
  "tr_CY": "Turks (Cyprus)",
  "tr_TR": "Turks (Turkije)",
  "tt": "Tataars",
  "tt_RU": "Tataars (Rusland)",
  "twq": "Tasawaq",
  "twq_NE": "Tasawaq (Niger)",
  "tzm": "Tamazight [Centraal-Marokko]",
  "tzm_MA": "Tamazight [Centraal-Marokko] (Marokko)",
  "ug": "Oeigoers",
  "ug_CN": "Oeigoers (China)",
  "uk": "Oekraïens",
  "uk_UA": "Oekraïens (Oekraïne)",
  "ur": "Urdu",
  "ur_IN": "Urdu (India)",
  "ur_PK": "Urdu (Pakistan)",
  "uz": "Oezbeeks",
  "uz_Arab": "Oezbeeks (Arabisch)",
  "uz_Arab_AF": "Oezbeeks (Arabisch, Afghanistan)",
  "uz_Cyrl": "Oezbeeks (Cyrillisch)",
  "uz_Cyrl_UZ": "Oezbeeks (Cyrillisch, Oezbekistan)",
  "uz_Latn": "Oezbeeks (Latijns)",
  "uz_Latn_UZ": "Oezbeeks (Latijns, Oezbekistan)",
  "vai": "Vai",
  "vai_Latn": "Vai (Latijns)",
  "vai_Latn_LR": "Vai (Latijns, Liberia)",
  "vai_Vaii": "Vai (Vai)",
  "vai_Vaii_LR": "Vai (Vai, Liberia)",
  "vi": "Vietnamees",
  "vi_VN": "Vietnamees (Vietnam)",
  "vun": "Vunjo",
  "vun_TZ": "Vunjo (Tanzania)",
  "wae": "Walser",
  "wae_CH": "Walser (Zwitserland)",
  "wo": "Wolof",
  "wo_SN": "Wolof (Senegal)",
  "xog": "Soga",
  "xog_UG": "Soga (Oeganda)",
  "yav": "Yangben",
  "yav_CM": "Yangben (Kameroen)",
  "yi": "Jiddisch",
  "yi_001": "Jiddisch (wereld)",
  "yo": "Yoruba",
  "yo_BJ": "Yoruba (Benin)",
  "yo_NG": "Yoruba (Nigeria)",
  "yue": "Kantonees",
  "yue_Hans": "Kantonees (vereenvoudigd)",
  "yue_Hans_CN": "Kantonees (vereenvoudigd, China)",
  "yue_Hant": "Kantonees (traditioneel)",
  "yue_Hant_HK": "Kantonees (traditioneel, Hongkong SAR van China)",
  "zgh": "Standaard Marokkaanse Tamazight",
  "zgh_MA": "Standaard Marokkaanse Tamazight (Marokko)",
  "zh": "Chinees",
  "zh_Hans": "Chinees (vereenvoudigd)",
  "zh_Hans_CN": "Chinees (vereenvoudigd, China)",
  "zh_Hans_HK": "Chinees (vereenvoudigd, Hongkong SAR van China)",
  "zh_Hans_MO": "Chinees (vereenvoudigd, Macau SAR van China)",
  "zh_Hans_SG": "Chinees (vereenvoudigd, Singapore)",
  "zh_Hant": "Chinees (traditioneel)",
  "zh_Hant_HK": "Chinees (traditioneel, Hongkong SAR van China)",
  "zh_Hant_MO": "Chinees (traditioneel, Macau SAR van China)",
  "zh_Hant_TW": "Chinees (traditioneel, Taiwan)",
  "zu": "Zoeloe",
  "zu_ZA": "Zoeloe (Zuid-Afrika)",
}