import Vue from 'vue'
import App from './App.vue'
import * as Sentry from '@sentry/browser'
import {
  Vue as VueIntegration
} from '@sentry/integrations'
import {
  Integrations
} from "@sentry/apm"
import {
  BootstrapVue,
  IconsPlugin
} from 'bootstrap-vue'
import router from './router'
import axios from "axios"
import moment from 'moment'
import numeral from 'numeral'
import Notifications from 'vue-notification'
import localeDescriptions from '@/locale-descriptions'
import * as validators from '@/validators.js'
import i18nPlugin from '@/plugins/i18n'
import locale_nl from '@/locale/nl_NL'

Vue.config.productionTip = false

// Sentry
let sentry_dsn = process.env.VUE_APP_SENTRY_DSN
if (!sentry_dsn && process.env.VUE_APP_SENTRY_PROJECT_ID) {
  sentry_dsn = 'https://'+process.env.VUE_APP_SENTRY_KEY+'@'+location.host+'/sentry/'+process.env.VUE_APP_SENTRY_PROJECT_ID
}

if (sentry_dsn) {
  console.log('Loading sentry', sentry_dsn)
  Sentry.init({
    dsn: sentry_dsn,
    release: window.SENTRY_RELEASE.id,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    integrations: [
      new Integrations.Tracing(),
      new VueIntegration({
        Vue,
        attachProps: true,
        logErrors: true,
        tracing: true,
      }),
    ],
    tracesSampleRate: 1.0,
    beforeSend(event) {
      console.log(event)
      try {
        if (event.exception.values[0].mechanism.handled) return null;
        // eslint-disable-next-line no-empty
      } catch (o_O) {}

      return event;
    }
  });
}

// Install BootstrapVue
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

// Notifications
Vue.use(Notifications)

Vue.use(i18nPlugin, locale_nl)

// Register number locales
numeral.register('locale', 'intl', {
  delimiters: {
    thousands: ' ',
    decimal: '.',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't'
  },
  ordinal: function (number) {
    return number === 1 ? 'er' : 'ème';
  },
  currency: {
    symbol: '€'
  }
});

numeral.register('locale', 'nl', {
  delimiters: {
    thousands: '.',
    decimal: ','
  },
  abbreviations: {
    thousand: 'k',
    million: 'mln',
    billion: 'mrd',
    trillion: 'bln'
  },
  ordinal: function (number) {
    var remainder = number % 100;
    return (number !== 0 && remainder <= 1 || remainder === 8 || remainder >= 20) ? 'ste' : 'de';
  },
  currency: {
    symbol: '€ '
  }
});

// Axios
{
  const instance = axios.create({
    baseURL: process.env.VUE_APP_API_ENDPOINT
  });

  Vue.prototype.$http = instance
}

Vue.prototype.$validators = validators

Vue.filter('localeDescription', function (value) {
  if (typeof value != 'string') return ''

  return localeDescriptions[value.replace('-', '_')]
})

Vue.filter('dateFormat', function (value) {
  return moment(value).format('DD-MM-YYYY')
})

Vue.filter('datetimeFormat', function (value) {
  return moment(value).format('DD-MM-YYYY H:mm')
})

Vue.filter('yesno', function (value) {
  value = (value === true || value == '1' || value == 1 || value == 'true' || value == 'True')
  return value ? 'Ja' : 'Nee';
})

Vue.filter('formatAccountingPeriod', function (value) {
  if (value === null) return '-'
  return moment(value, "YYMM").format('MMM YYYY')
})

//Vue.filter('numberFormat', function (value) {
//  return numeral(value).format('0.[0000]')
//})

Vue.filter('numberFormat', function (value, decimals = 0, locale = 'nl') {
  if (isNaN(value)) return '-'

  numeral.locale(locale)

  return numeral(parseFloat(value)).format('0,0.' + '0'.repeat(decimals))
})

Vue.filter('percentage', function (value, locale = 'nl') {
  if (isNaN(value)) return '-'

  numeral.locale(locale)
  value = value * 100
  const decimals = 10
  return numeral(parseFloat(value)).format('0,0.[' + '0'.repeat(decimals) + ']') + ' %'
})

Vue.filter('jsonPretty', function (value) {
  return JSON.stringify(value, null, 2)
})

new Vue({
  render: h => h(App),
  data: {
    me: null,
    adminToken: null,
    loggedIn: false,
    fieldDefs: null,
    pageSnippets: null,
    crumbs: [],
    countries: {
      "TH": "Thailand",
      "BE": "België",
      "FR": "Frankrijk",
      "SE": "Zweden",
      "AF": "Afghanistan",
      "ES": "Spanje",
      "NL": "Nederland",
      "DE": "Duitsland",
      "AE": "Verenigde Arabische Emiraten",
      "AD": "Andorra",
      "GB": "Verenigd Koninkrijk",
    },
  },
  watch: {
    loggedIn(val) {
      if (val) {
        this.$http.get('release-fields')
          .then(response => {
            this.$data.fieldDefs = response.data
          })
          .catch((error) => {
            console.log(error)
          })

        this.$http.get('page-snippets', { params: { cached: true } })
          .then(response => {
            let snippets = {}
            response.data.forEach(s => {
              snippets[s.Name] = s.Contents
            })

            this.$data.pageSnippets = snippets
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }
  },
  methods: {
    scroll(x = 0, y = 0) {
      window.scroll(x, y)
    }
  },
  computed: {
    countryOptions() {
      let out = []
      for (const [k, v] of Object.entries(this.countries)) {
        out.push({
          value: k,
          text: v
        })
      }
      return out
    }
  },
  router,
  created() {

  }
}).$mount('#app')