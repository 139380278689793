export function artworkFile (file) {
  let errors = []
  const info = file.info

  if (info.format != 'JPEG') {
    errors.push('Bestand is niet van het formaat JPEG')
  } else {
    if (info.width < 3000 || info.height < 3000) 
      errors.push('Afbeelding is kleiner dan 3000x3000 pixels')

    if (info.width > 6000 || info.height > 6000) 
      errors.push('Afbeelding is groter dan 6000x6000 pixels')

    if (info.colorspace != 'sRGB')
      errors.push('Afbeelding heeft niet de sRGB colorspace')

    if (info.quality < 75) 
      errors.push('Afbeelding heeft een lagere kwaliteit dan 75')
  }

  if (errors.length == 0)
    return true
  return errors
}

export function audioFile (file) {
  let errors = []
  const info = file.info

  if (info.format != 'wav') {
    errors.push('Bestand is niet van het formaat WAV')
  } else {
    if (info.bitsPerSample < 16) 
      errors.push('Audio bestand heeft minder dan 16 bits per sample')

    if (info.sampleRate < 44100) 
      errors.push('Audio bestand heeft een lagere sample rate dan 44100 Hz')
  }

  if (errors.length == 0)
    return true
  return errors
}